import { useNuxtApp } from '#app'
import { useAPI, useCacheData, useSharedPromise } from '#imports'
import { productDataForCarousel } from '@/utils/product-carousels'

import type { ProductCollection } from '@/modules/nuxt-api/models/ProductCollection'

type Actions = {
  getHomeCollections: () => Promise<ProductCollection[] | undefined>
  getBestsellerCollections: () => Promise<ProductCollection[] | undefined>
  getProductPageCollections: (code: string) => Promise<ProductCollection[] | undefined>
  getCartProductCollections: () => Promise<ProductCollection[] | undefined>
}

export function useProductCollections (): Actions {
  const nuxtApp = useNuxtApp()
  const api = useAPI()

  async function getHomeCollections () {
    const { value, addToCache } = await useCacheData<ProductCollection[]>('home-product-collections')

    if (value) {
      return value
    } else {
      const homeCollections = await nuxtApp.runWithContext(() => useSharedPromise('product-collections-home', async () => {
        const { getHomeProductCollectionsRequest } = api.productCollection()
        return await getHomeProductCollectionsRequest()
      }))

      if (homeCollections?.length) {
        const homeCollectionsShort = homeCollections.map(
          el => ({ ...el, productsList: productDataForCarousel(el.productsList) })
        )
        await addToCache(homeCollectionsShort)

        return homeCollectionsShort
      }

      return homeCollections
    }
  }

  async function getBestsellerCollections () {
    const { value, addToCache } = await useCacheData<ProductCollection[]>('product-collections-bestsellers')

    if (value) {
      return value
    } else {
      const bestsellerCollections = await nuxtApp.runWithContext(
        () => useSharedPromise('product-collections-bestsellers', async () => {
          const { getBestsellerProductCollectionsRequest } = api.productCollection()
          return await getBestsellerProductCollectionsRequest()
        }
        ))
      if (bestsellerCollections?.length) {
        await addToCache(bestsellerCollections)
      }

      return bestsellerCollections
    }
  }

  async function getProductPageCollections (code: string) {
    return await nuxtApp.runWithContext(() => useSharedPromise(['product-collections', code], async () => {
      const { getProductCollectionsForProductPageRequest } = api.productCollection()
      return await getProductCollectionsForProductPageRequest(code)
    }))
  }

  async function getCartProductCollections () {
    const productCollections = await nuxtApp.runWithContext(() => useSharedPromise(['product-collections'], async () => {
      const { getCartProductCollectionsRequest } = api.productCollection()
      return await getCartProductCollectionsRequest()
    }))

    return productCollections
  }

  return {
    getHomeCollections,
    getBestsellerCollections,
    getProductPageCollections,
    getCartProductCollections
  }
}
